/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import CPHradio from "../../common/pcsq/CPHradio";
import { RxUtil } from "@/assets/util.js";
import { format, formatHM } from "@/assets/app.js";
export default {
  name: "lcfqPcsq",
  components: {
    LCFQheader,
    CPHradio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "派车申请单",
      showSqsj: false,
      showCcsj: false,
      showListpage: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      currentccTime: new Date(),
      showGinfo: false,
      //userSelect
      single: true,
      allowSelect: true,
      showUserDialogsx: false,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      actDefId: "",
      meetingUi: false,
      meetingList: [],
      keyList: [],
      data: {
        sqbh: "",
        sqr: "",
        jsy: "",
        ycks: "",
        sxry: "",
        sqsj: "",
        ccsj: "",
        sfsyyk: '是',
        ycsy: '',
        cph: '',
        syqck: '',
        ccdd: '',
        mdd: '',
        yjxsgls: '',
        bz: ''

        // ycsy = resData.ycsy;
        // cph = resData.cph;
        // syqck = resData.syqck;
        // ccdd = resData.ccdd;
        // mdd = resData.mdd;
        // yjxsgls = resData.yjxsgls;
        // bz = resData.bz;
      }
    };
  },

  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let newDate = new Date();
      this.data.sqsj = formatHM(newDate);
      this.data.ccsj = formatHM(newDate);
      let instId = this.$route.params.instId;
      let solId = this.$route.params.solId;
      //if (instId == 0){
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(res => {
        this.solId = res.data.bpmSolution.solId;
        this.actDefId = res.data.bpmSolution.actDefId;
        this.boDefId = res.data.formModels[0].boDefId;
        this.formKey = res.data.formModels[0].formKey;
        this.data.sqbh = res.data.formModels[0].jsonData.sqbh;
        // let sqr = res.data.formModels[0].jsonData.sqr;
        // let sqr_name = res.data.formModels[0].jsonData.sqr_name;
        // let sqrObj = [{id:sqr,text:sqr_name}];
        // this.data.sqr = JSON.stringify(sqrObj);
        // let ycks = res.data.formModels[0].jsonData.ycks;
        // let ycks_name = res.data.formModels[0].jsonData.ycks_name;
        // let ycksObj = [{id:ycks,text:ycks_name}];
        // this.data.ycks = JSON.stringify(ycksObj);
      });
      //}

      //xzoa/sys/core/sysDic/listByKey.do?key=CCDD&_=1651026051475
      var urlccdd = _baseUrl + "/sys/core/sysDic/listByKey.do?key=CCDD";
      this.$ajax.post(urlccdd).then(res => {
        //出车地点选择项。
        for (var i of res.data) {
          this.meetingList.push(i.name);
          this.keyList.push(i.key);
        }
      });
    },
    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+instId;
        // this.$ajax.post(url).then(res=>{

        // 	this.data = res.data;
        //       let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
        //       this.$ajax.post(urlUser1).then(resdlr=>{
        //       let dlr = [{id:resdlr.data.rowList[0].userId,text:resdlr.data.rowList[0].fullname}];
        //         this.data.dlrName = JSON.stringify(dlr);
        //       })
        // });

        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          let sqrObj1 = [{
            id: resData.ycks,
            text: resData.ycks_name
          }];
          this_.data.ycks = JSON.stringify(sqrObj1);
          let sqrObj2 = [{
            id: resData.sqr,
            text: resData.sqr_name
          }];
          this_.data.sqr = JSON.stringify(sqrObj2);
          let sqrObj3 = [{
            id: resData.jsy,
            text: resData.jsy_name
          }];
          this_.data.jsy = JSON.stringify(sqrObj3);
          let idarr = resData.sxry.split(',');
          let namearr = resData.sxry_name.split(',');
          let sqrObj4 = [];
          for (var i = 0; i < idarr.length; i++) {
            sqrObj4.push({
              id: idarr[i],
              text: namearr[i]
            });
          }
          this_.data.sxry = JSON.stringify(sqrObj4);
          this_.data.sqbh = resData.sqbh;
          this_.data.sqsj = resData.sqsj;
          this_.data.ccsj = resData.ccsj;
          this_.data.ycsy = resData.ycsy;
          this_.data.cph = resData.cph;
          this_.data.syqck = resData.syqck;
          this_.data.ccdd = resData.ccdd;
          this_.data.mdd = resData.mdd;
          this_.data.yjxsgls = resData.yjxsgls;
          this_.data.bz = resData.bz;
          if (resData.sfsyyk == 'syyk') {
            this_.data.sfsyyk = '是';
          } else {
            this_.data.sfsyyk = '否';
          }
        });
        /***/
      } else {
        this.getUser();
      }
    },
    meetingFunction(value) {
      this.data.ccdd_name = value;
      for (var i in this.meetingList) {
        if (this.meetingList[i] == value) {
          this.data.ccdd = this.keyList[i];
        }
      }
      console.log(this.data.ccdd, this.data.ccdd_name);
      this.meetingUi = false;
    },
    //获取用户信息
    getUser() {
      this.data.cgsqr = RxUtil.getCache("userName");
    },
    onConfirmSqsj(timestamp) {
      this.data.sqsj = formatHM(timestamp);
      this.showSqsj = false;
    },
    onConfirmCcsj(timestamp) {
      this.data.ccsj = formatHM(timestamp);
      this.showCcsj = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.data.sqr = '';
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUserDialog2(vm) {
      this.data.jsy = '';
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    selectUserDialogsx(vm) {
      this.showUserDialogsx = true;
      this.$refs.sysUsersx.search();
      // 设置选择用户。
      this.$refs.sysUsersx.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUsersx(data) {
      console.log(data);
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      console.log(json);
      this.nodeUserVm.setValue(json);
      this.showUserDialogsx = false;
    },
    //科室
    selectGroupDialog(vm) {
      this.data.ycks = '';
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.cph = item.CPH;
      this.data.sydd = item.CCKS;
      this.data.syqck = item.CK;
      this.showListpage = false;
    },
    datas() {
      // if(this.data.sqbh!="" && this.data.sqsj!="" && this.data.ycks!="" && this.data.ycsy!="" && this.data.sqr!="" && this.data.jsy!="" && this.data.cph!="" && this.data.syqck!="" && this.data.sxry!="" && this.data.ccsj!="" && this.data.ccdd!="" && this.data.mdd!="" && this.data.yjxsgls!="" && this.data.sfsyyk!=""){
      let ycks = JSON.parse(this.data.ycks);
      let sqr = JSON.parse(this.data.sqr);
      let jsy = JSON.parse(this.data.jsy);
      let sxry = JSON.parse(this.data.sxry);
      let sfsyyk;
      let sxryId = '';
      let sxryStr = '';
      for (var i = 0; i < sxry.length; i++) {
        if (i == 0) {
          sxryId = sxry[i].id;
          sxryStr = sxry[i].text;
        } else {
          sxryId = sxryId + "," + sxry[i].id;
          sxryStr = sxryStr + "," + sxry[i].text;
        }
      }
      if (this.data.sfsyyk == "是") {
        sfsyyk = "syyk";
      } else {
        sfsyyk = "bsyyk";
      }
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            sqbh: this.data.sqbh,
            sqsj: this.data.sqsj,
            ycks: ycks[0].id,
            ycks_name: ycks[0].text,
            ycsy: this.data.ycsy,
            sqr: sqr[0].id,
            sqr_name: sqr[0].text,
            jsy: jsy[0].id,
            jsy_name: jsy[0].text,
            cph: this.data.cph,
            cph_name: this.data.cph,
            syqck: this.data.syqck,
            sxry: sxryId,
            sxry_name: sxryStr,
            ccsj: this.data.ccsj,
            ccdd: this.data.ccdd,
            ccdd_name: this.data.ccdd_name,
            sydd: this.data.sydd,
            mdd: this.data.mdd,
            yjxsgls: this.data.yjxsgls,
            sfsyyk: sfsyyk,
            bz: this.data.bz,
            sfsyyk_name: this.data.sfsyyk
          }
        }]
      };
      console.log(jsonDataObj);
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
      // }else{
      //   this.errorToast("信息未填完整！");
      // }
    },

    onSubmit() {
      if (this.data.sqbh == "" || this.data.sqsj == "" || this.data.ycks == "" || this.data.ycsy == "" || this.data.sqr == "" || this.data.jsy == "" || this.data.cph == "" || this.data.syqck == "" || this.data.sxry == "" || this.data.ccsj == "" || this.data.ccdd == "" || this.data.mdd == "" || this.data.yjxsgls == "" || this.data.sfsyyk == "") {
        this.errorToast("信息未填完整！");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        console.log(res);
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败或信息未填完整！", "1800");
        }
      });
    },
    onPreservation() {
      if (this.data.sqbh == "" || this.data.sqsj == "" || this.data.ycks == "" || this.data.ycsy == "" || this.data.sqr == "" || this.data.jsy == "" || this.data.cph == "" || this.data.syqck == "" || this.data.sxry == "" || this.data.ccsj == "" || this.data.ccdd == "" || this.data.mdd == "" || this.data.yjxsgls == "" || this.data.sfsyyk == "") {
        this.errorToast("信息未填完整！");
        return;
      }
      console.log(this.data.sxry_name);
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败或信息未填完整！", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};